import * as React from 'react';
import { Link } from 'react-router-dom';


const BonusItem = (props) => {
    const copiePromo = (val) => {

        // Copy the text inside the text field
        navigator.clipboard.writeText(val.toString());
        console.log(val);
    }

    const goToPage = (param) => {
        //alert(param);
    }

    return (
        <div className=' col-sm-10  col-md-5 col-lg-3 mb-3 mr-sm-3 shadow-custom font-responsive px-2' style={{minWidth: '280px'}}>
            <div onClick={() => { goToPage(1) }} class="d-flex bookmaker-info cursor-pointer  align-items-center">
                <div  style={{ width: '80px' }}>
                    <img class="mb-3" id='bookmaker-img' style={{width: '100%',height: '70px'}} src={props.data.logo} alt="Card cap" /><br />
                    <span className="text-gold d-flex justify-content-center">
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-regular fa-star"></i>
                    </span>
                </div>
                <div className="p-2 px-3 clearfix">

                    <p class="card-text pt-3 text-justify clearfix">Bonus d'inscription  allant jusqu'a 130 000 FCFA <b>({props.data.offre_bienvenue}$) </b> grace au  code promo <span className="box-bordered" onClick={() => { copiePromo(props.data.code_promo) }} title="Copy to clipboard" data-toggle="tooltip" data-placement="top"><b className="text-theme text-right mx-2 text-focus" id="promoCode">{props.data.code_promo}</b><i title="Copier" class="fa-regular fa-copy"></i></span></p>
                    <a href={props.data.lien} target="_blank" rel="noreferrer" class="btn btn-sm bg-theme text-light float-right"> <i class="fa-sharp fa-solid fa-gift"> </i> Recevoir ton Bonus  <i class="fa-sharp fa-solid fa-gift"> </i></a>
                </div>
            </div>
            <Link  className='bookmaker-link' to={'/meilleures-bookmakers/'+props.data.nom.toString().toLowerCase()}>Lire l'Article ..</Link>
        </div>



    )
}

export default BonusItem;

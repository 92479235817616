import { combineReducers, configureStore } from '@reduxjs/toolkit';
import bonusSlice from './bonusSlice';
import bookmakerSlice from './bookmakerSlice';
import socialsSlice from './socialsSlice';


const reduces = combineReducers({
  bookmaker: bookmakerSlice,
  bonus: bonusSlice,
  socials: socialsSlice
})

export default configureStore({
  reducer: reduces,
});
import * as React from "react";

import Logo from '../assets/images/logo-mini-blanc.svg'
import PUBUNIVERSTAR from '../assets/images/presentation.png'
import Football from '../assets/images/football3.png'
import Football2 from '../assets/images/football1.jpg'
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Home() {

  const currentBookmaker = useSelector((state) => state.bookmaker.value);

  React.useEffect(() => {
    document.querySelector('meta[name="description"]').setAttribute("content", "Le Comparatifs des  meilleurs bookmakers du monde, atouts, inconveniants et astuces");
  }, [])


  return (
    <main className="px-2  w-100">
      <div className="w-100 py-2  px-5  d-flex flex-column justify-content-center month-bonus" style={{ backgroundColor: '#e90052' }}>
        <div className="d-flex py-4 align-items-center  justify-content-around">
          <span id="month-title" className="py-1" style={{ backgroundColor: '#e90052' }}>
            <img src={Logo} className="mx-2" style={{ height: '100%', maxHeight: '100%', color: 'white' }} alt="logo-bookmaker-du-mois" />
            <b className="text-light mx-2 text-uppercase">{currentBookmaker?.nom}</b>
          </span>
          <img className="img-thumbnail img-fluid bookmaker-mensuel-img" src={currentBookmaker?.logo} alt="logo-bookmaker-du-mois" />
          <h2 className="title-1 text-uppercase text-light">LE Meilleure Bookmaker du  mois de Novembre</h2>
          <img className="img-thumbnail img-fluid bookmaker-mensuel-img" src={currentBookmaker?.logo} alt="logo-bookmaker-du-mois" />
        </div>
        <p className="text-light text-sm-center mx-sm-5">
          Le Meilleur Bookmaker d'apres les sondage Mensuel, Obtenez Un bonus  de bienvenue incroyable, <b style={{ fontWeight: 900 }}>200% </b>de bonus  sur le premier  depot, Un Bonus pour votre anniversaire et une remise allant jusqu'a <b style={{ fontWeight: 900 }}>20%</b> en fin de mois sur vos pertes en inserant le code promo <b style={{ fontWeight: 900 }}>{currentBookmaker.code_promo}</b> pendant votre inscription.
        </p>
        <div className="d-flex  justify-content-center align-items-center">
          <a className="mx-4 btn btn-large bg-light bonus-btn text-dark align-middle" target='_blanck' href={currentBookmaker?.lien}><i class="fa-sharp fa-solid fa-gift text-uppercase" style={{ color: '#e90052' }}> </i> <span className="mx-1">Reçoit Ton  Bonus Maintenant</span> <i style={{ color: '#e90052' }} class="fa-sharp fa-solid fa-gift"> </i></a>
        </div>

        <div className="d-flex my-4  justify-content-center align-items-center">
          <i class="fa-sharp fa-solid fa-chevron-right text-light text-uppercase"> </i>
          <i class="fa-sharp fa-solid fa-chevron-right text-light text-uppercase"> </i>
          <i class="fa-sharp fa-solid fa-chevron-right text-light text-uppercase"> </i>
          <a className="mx-3 text-light font-weight-500 align-middle  text-focus text-center" target='_blanck' style={{ fontSize: '0.9em' }} href={currentBookmaker?.lien}> <b className="text-responsive text-uppercase">CODE PROMO : {currentBookmaker.code_promo}</b> </a>
          <i class="fa-sharp fa-solid fa-chevron-left text-light text-uppercase"> </i>
          <i class="fa-sharp fa-solid fa-chevron-left text-light text-uppercase"> </i>
          <i class="fa-sharp fa-solid fa-chevron-left text-light text-uppercase"> </i>
        </div>
      </div>
      <h1 className="title-1 text-uppercase">Analyse Statistiques et Pronostics football</h1>
      <p>
        Nous vous proposons une plateforme complete de statistiques de football et des pronostics de matchs a travers le monde, afin que vous puissiez atteindre le résultat que vous souhaitez. Vous avez également du contenu qui vous aide à en savoir plus sur cet art et à améliorer vos résultats.</p>
      <p>
        <p>L'univers  des  paris sportifs a connu  une enorme evolution ses dix dernieres annee grace aux innovation technologique, l'evolution des <b>Smartphone</b> etant au pilie de cette nouvelle l'aire a permie l'apprition de bookmaker  de plus en plus nombreux aussi bien <b>Local</b> que  <b>International</b> plusieurs de demarque du lot
          par  des <a href={currentBookmaker.lien} rel="noreferrer" target='_blank' >Site Web</a> et des <a href={currentBookmaker.applicationMobile ? currentBookmaker.applicationMobile : currentBookmaker.lien} target='_blank' rel="noreferrer">applications mobile</a>  nous permettant  de pariers et retirer de l'argent partouts dans le monde grace
          divers moyen de paiement regroupant des carte de credit, porte feuille ellectronique, virement  bancaire, mobile money  et cryptomonaie.</p>
        Le Paris sportif est une  activite qui a la difference des jeux de casino ne repose pas uniquement sur la chance, des bases de  connaissance et statisque(recapitulatif des dernieres rencontre et  performance des different  joueur) sont neccessaire tirer son epingle du jeux. Bonne nouvelle pour, certain cannaux  tel que <a style={{ textDecoration: 'none' }} href="https://t.me/univerdesparieurs" target='_blank' rel="noreferrer">Universtar Pronostics</a> fournissent regulierement  des  astuces et outils pour mieux reussir ses pronostics sportifs.
      </p>

      <p>
        Maintenant que  nous avons la solution pour apprendre a faire des pronostics,  la Question la plus important a se poser a present est , comment choisir le bookmaker  pour placer ses paris sportifs ?
        parcourez la section <a href="/meilleures-bookmakers">meilleurs bookmakers</a> et opter pour le bookmaker qui vous correspond  le mieux.
      </p>

      <p>Afin de faire  de vous de meilleures parieurs, notre site vous  accompagne  suivant des principe de parieurs  a  succes, regrouper en 7 Objectifs distintcs.</p>


      <h2 className="title-2 text-uppercase">Objectif 1 : Mieux Reussir ses pronostics</h2>

      <p>
        Notre  equipe de professionnels regroupe des membres cumulant  <b>7 a 10 ans d'expérience</b> en  pronostics  sportifs, a cette effets des article detaillants des astuces et failles a exploiter pour mieux afuter vos analyse, differents type de pronostics pour gagner plus d'argent seront detailer dans la section <a href="/blog">Blog</a> . Il n'est pas toujours evident de faire des pronostics fiable lorsque  vous debuter, des pertes arrivent alors  tres vite, pour vous accompagner dans debut et vous permettre  de demarrer du bon pied? des pronostics sur rencontre sportives
        sont faits par nos professionnel et publier dans la section <a href="/pronostics">pronostics</a> de notre site web.
      </p>

      <h2 className="title-2 text-uppercase">Objectif 2 : Obtenir les meilleures avantages de votre bookmaker</h2>

      <p>
        Afin de tirez meilleurs partie  des  atouts  fournis par  le differents bookmakers il est neccessaire de bien choisir au  prealable ce dernier, Un bookmaker disponible et accessible est  essemtielle pour l'evolution d'un parieurs, il en existe  de nombreux en foncction de votre  pays de residence,
        cchacun proposant des avantage particulier et  des plateformes diverse de pari,  (site web,  application, Point physique). Avant de choisir le votre, les element suivant sont a prendre en compte :
        <div className="row">
          <div className="col-6">
            <ul className="mx-3 mt-3">
              <li>L'anciennete</li>
              <li>les Types de cotes</li>
              <li>Les Bonus offerts</li>
              <li>La vitesse des retraits</li>
              <li>Les Modes de paiements</li>
            </ul>
          </div>
          <div className="col-6">
            <ul className="mt-3">
              <li>Les Modes de paiements</li>
              <li>Les Plateformes utilisable</li>
              <li>Le  montant depot minimum</li>
              <li>Le  montant de gains maximal</li>
              <li>La Disponibilite du service client</li>
            </ul>
          </div>
        </div>

      </p>
      Decouvrez plus sur les avantages et  inconvnients des bookmaker de votre region en consultant la page dedier au  <Link to={'/meilleures-bookmakers'}>Bookmakers</Link> de notre  site.
      <p>
      </p>

      <div className="w-100 bg-theme month-bonus">
        <div className="row px-5 py-4">

          <div className="col-sm-5 pt-2 text-center align-items-center  d-flex flex-column">
            <h2 className="text-light pb-3 text-uppercase"><b> Enfin Un Pronostiqueur Gratuit !!</b></h2>
            <p className="text-light text-justify">
              Rejoignez  une communauter de  15 000 passionnes de sports, achanger votre experiencec  et beneficier d'astuce et   pronotics inedit analyser par  les meilleures chaque jour  pour  reussir vos paris sportis, Profitez du levier de votre communauter devenez millionaire  grace aux paris sportifs
               </p>
            <div className="d-flex my-2">
              <a className="btn btn-sm text-right bg-light text-dark" target='_blanck' href='https://t.me/universdesparieurs'><i class="fa-brands fa-telegram text-dark text-uppercase"> </i> <span className="mx-1">Rejoindre  la communauter</span> <i class="fa-brands fa-telegram text-dark"> </i> </a>
            </div>
          </div>
          <div className="col-sm-7 d-flex justify-content-center align-items-center">
            <img src={PUBUNIVERSTAR} className="mx-2" style={{ height: '250px', maxHeight: '250px', color: 'white' }} alt="logo-bookmaker-du-mois" />
          </div>

        </div>

      </div>

      <h2 className="title-2 text-uppercase">Objectif 3 : Repondre a  vos besoins</h2>

      <p>

       Il est  frequent pour les nouveaux parieurs  de  se retrouver  heurter a des  difficulter aussi bien au niveau de la comprehention des possibilite  de paris disponible qu'au niveau des interface et fonctionnaliter disponible sur la plateforme du  bookmakers.
       Nous  mettons a votre  disposition  un service  hautement qualifier pour repondre a vos besoins et resoubre les probleme  que vous rencontrer.
       </p>

      <h2 className="title-2 text-uppercase">Objectif 4 : Fournir une communaute de parieurs</h2>

      <p>
      Un aspect important pour  evoluer dans  les pronostic sportifs et commencer a  remporter  beaucoup plus d'argent  est   de  rejoindre  une  communauter  de parrieurs actives, l'analyse commune  permet  de  deceller  beaucoup plus de details qui  aurait  echapper a la virgillancec d'un seul esprit.
      Partagez et  echangez vos  pronostique , recoltez les remarque et appreciations differents membres pour mieux  toucher votre objectif final <b>"GAGNEZ"</b>, une communauter tres conseillez au nouveaux parieurs actuellement est <a  target='_blank' href="https://t.me/fifaPronoTips" rel="noreferrer">Fifa Prono Tips</a> qui  comptent pres de 2000 membres  actif qui pourrait  servir  de
      levier pour bien debuter.
       </p>

      <h2 className="title-2 text-uppercase">Objectif 5 : Trouver une communaute pour mieux evoluer</h2>

      <p>
       Vivez  avec nous chaque mois des evements  speciaux autour  des festivites sportives, ds offre  incroyables vous  attendents avec une  chance pour pour vous de remporter des bonus chez les differents bookmakers de votre  pays </p>
      <div className="w-100 bg-theme text-center month-bonus">
        <div className="row px-5 py-4">
          <div className="col-sm-7 d-flex justify-content-center align-items-center">
            <img src={Football} className="mx-2" style={{ height: '250px', maxHeight: '250px', color: 'white' }} alt="logo-bookmaker-du-mois" />
          </div>

          <div className="col-sm-5 pt-2 align-items-center d-flex flex-column">
            <h2 className="text-light pb-3 text-uppercase"><b>888STARZ : Un bookmaker  fiable</b></h2>
            <p className="text-light text-justify">
              L'un  des Bookmaker les plus innovant du domaine d'apres les derniers sondage, Fiable et disponible sur  les 5 continents,<b className="text-focus">100% de bonus</b> sur le premier  depot, Un Bonus pour votre anniversaire et une remise allant jusqu'a <b className="text-focus">20%</b> en fin de mois sur vos pertes en inserant le code promo <b className="text-focus">{currentBookmaker.code_promo}</b> pendant votre inscription.
            </p>
            <div className="d-flex  my-2">
              <a className="btn btn-sm bg-light bonus-btn text-dark" target='_blanck' href='https://1xbetcm.com'><i class="fa-sharp fa-solid fa-basketball text-dark text-uppercase"> </i> <span className="mx-1 text-uppercase">Jusqu'a 100$ de bonus offerts</span> <i class="fa-sharp fa-solid fa-basketball text-dark text-uppercase"> </i> </a>
            </div>
          </div>
        </div>

      </div>
      <h2 className="title-2 text-uppercase" align='center'>Vivez  votre  passion sur MIMO SPORTS !!</h2>
    </main>
  );
};

export default Home;
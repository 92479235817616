import * as React from "react";
import { useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { getPaiementsMethods } from "../services/client";

function BookmakersPaiements(props) {



  const bonus = useSelector((state) => state.bonus.values);


  const copiePromo = (val) => {

    // Copy the text inside the text field
    navigator.clipboard.writeText(val.toString());
    console.log(val);
  }

  const [paiements, setPaiements] = React.useState([])


  React.useEffect(() => {
    getPaiementsMethods().then(result => {
      setPaiements(result.data);
    }).catch(err => console.log(err.message))
  }, [])



  const [sublink, setSubLink] = React.useState(false)


  const location = useLocation();

  React.useEffect(() => {
    let path = location.pathname.substring(1).split('/');
    if (path.length > 1) {
      setSubLink(true);
    } else {
      setSubLink(false);
    }
  }, [location])

  return (
    <div className="mt-3"  >
      <div>
        <h1 align='center' className="text-uppercase text-sm mb-4"><b>QUEL MODE  DE PAIEMENTS RECHERCHEZ VOUS CHEZ VOTRE BOOKMAKER ? </b> </h1>
        <p className="text-justify px-4">
          Disposez d'un mode de paiement rapide et  fiable chez sont  bookakers est un element indispensable pour tous bon parieurs,nous avons regrouper ici tous les mode de paiements ccommun ainsi  que les bookmakers  ou vous pouvez les  retrouver, Faite votre choix dans  la liste ci-dessous
        </p>
        <br></br>
        <div class="row d-flex align-items-center justify-content-around">
          {paiements.map(item => (
            <div class="row col-sm-4 py-5">
              <div class="col-6 card-img-top d-flex justify-content-center">
                <img style={{ width: '150px', height: '100px' }} src={item.logo} alt="Card cap" />
              </div>

              <div className="col-6 d-flex align-items-center" class="card-body">
                <div>
                  <h5 class="card-title px-2">{item.nom}</h5>
                  <Link to={'/bookmakers-paiements/' + item.slug.toLowerCase().replace(' ', '-')} className='px-2'>Consulter</Link>
                </div>
              </div>
            </div>
          ))}
        </div>

      </div>
    </div>
  )
};

export default BookmakersPaiements;
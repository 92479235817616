import * as React from 'react';


const BookmakerItemMobile = (props) => {
    const copiePromo = (val) => {

        // Copy the text inside the text field
        navigator.clipboard.writeText(val.toString());
        console.log(val);
    }

    const goToPage = (param) => {
        alert(param);
    }

    return (
        <>
            <div className='bookmaker-wrapper col-12 p-2'>
                <div className='row px-0 d-flex align-items-center justify-content-center'>
                    {/*   <span className='classement'>1</span> */}
                    <img src={props.data.logo} className='mr-3' width={'120'} alt="logo-bookmaker" />
                </div>
                <div className='row'>
                    <div className='col-sm-12'>
                        <table className='w-100 bookm  table table-borderless'>
                            <tbody>
                                <tr>
                                    <td>Bonus Premier Depot</td>
                                    <td><b className="text-focus px-3">{props.data.offre_bienvenue} $</b></td>
                                    <td> <span>
                                        {props.data?.paysDisponible?.map(item => {
                                            return (
                                                <i class={"mx-3 fi fi-" + item.drapeau}></i>
                                            )
                                        })}
                                    </span></td>
                                </tr>
                                <tr>

                                    <td>Code promo</td>
                                    <td><span className="box-bordered px-3" onClick={() => { copiePromo(props.data.code_promo) }} title="Copy to clipboard" data-toggle="tooltip" data-placement="top"><b className="text-theme text-right mx-2 text-focus" id="promoCode">{props.data.code_promo}</b><i title="Copier" class="fa-regular fa-copy"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><h4 className='text-focus'>Avantages</h4></td>
                                    <td> <ul style={{ listStyleType: 'none' }} className="px-3">
                                        {props.data.avantages.split('\n').map(val => {
                                            return (
                                                <li><i class="mr-2 fas fa-star"></i>{val.substring(1)}</li>
                                            )
                                        })}
                                    </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td> <h4 className='text-focus'>Inconvenients</h4></td>
                                    <td> <ul style={{ listStyleType: 'none' }} className="px-3">
                                        {props.data.inconvenients.split('\n').map(val => {
                                            return (
                                                <li><i class="mr-2 fas fa-x"></i>{val.substring(1)}</li>
                                            )
                                        })}
                                    </ul></td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>

                <div className='row d-flex mb-3  justify-content-center'>
                    <a href={"/meilleures-bookmakers/" + props.data.nom} class="btn btn-block my-1 mx-3 btn-outline-info"> <i class="fa-sharp fa-solid fa-file"> </i> Plus de Details <i class="fa-sharp fa-solid fa-file"> </i></a>
                    <a href={props.data.lien} target="_blank" rel="noreferrer" class="btn btn-block my-1 mx-3 bg-theme text-light"> <i class="fa-sharp fa-solid fa-gift"> </i> Obtenir le Bonus  <i class="fa-sharp fa-solid fa-gift"> </i></a>

                </div>
            </div>
        </>



    )
}

export default BookmakerItemMobile;

import * as React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { sendContactForm } from '../services/client';
//import Souscription from "../assets/images/1win.jpg"


const Contact = () => {

    const [nom, setNom] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [message, setMessage] = React.useState("")
    const [loading, setloading] = React.useState(false)

    const socials = useSelector((state) => state.socials);

    const sendData = () => {
        setloading(true)
        if (nom === '' || email === '' || message.length < 5) {
            alert('Veiller remplir correctement le formulaire');
        } else {
            sendContactForm(
                {
                    nom: nom,
                    email: email,
                    message: message
                }
            ).then(val => {
                if (val.status === 201) {
                    alert('Votre message  a ete envoyer ' + val.data.nom);
                    resetValue('');
                } else {
                    alert('Erreur' + val.data.message);
                }
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                setloading(false)
            })
        }
    }

    const resetValue = () => {
        setNom('');
        setEmail('');
        setMessage('');
    }
    return (
        <div>
            {loading ? (
                <div className="loader-container" style={{position: 'fixed',top: 0,bottom:0,left: '0',right: '0'}}>
                    <div className="spinner"></div>
                </div>
            ) : null}
            <h1 align='center' className="text-uppercase text-sm mb-4 mt-4"><b>Laissez Nous Un Message</b></h1>
            <div className='d-flex justify-content-center contact-form'>
                <form method='post' onSubmit={() => { return false }} className='w-75 d-block'>
                    <div class="form-group">
                        <label for="">Nom</label>
                        <input type="text" name="nom" id="" class="form-control" onChange={(val) => { setNom(val.target.value) }} value={nom} placeholder="Saisissez votre nom" aria-describedby="helpId"></input>
                        <small id="helpId" class="text-muted">Comment devons  nous vous appeller?</small>
                    </div>

                    <div class="form-group">
                        <label for="">Email</label>
                        <input type="email" name="email" id="" value={email} onChange={(val) => { setEmail(val.target.value) }} class="form-control" placeholder="Saisissez votre addresse Email" aria-describedby="helpId"></input>
                        <small id="helpId" class="text-muted">Ou souhaitez-vous recevoir notre reponse?</small>
                    </div>

                    <div class="form-group">
                        <label for="">Votre Message</label>
                        <textarea name="" id="" class="form-control" value={message} onChange={(val) => { setMessage(val.target.value) }} rows={7} placeholder="Saisissez votre Message" aria-describedby="helpId" ></textarea>
                        <small id="helpId" class="text-muted">Comment  pouvons nous vous aider?</small>
                    </div>

                    <button onClick={(e) => { e.preventDefault(); sendData() }} className='btn bg-theme btn-block btn-secondary' style={{ height: '45px', borderRadius: 0, borderColor: '#fff', outline: 'none' }}>
                        Envoyer <i class="fa fa-envelope-o" aria-hidden="true"></i>
                    </button>
                </form>
            </div>

            <h1 align='center' className="text-uppercase text-sm mb-4 mt-4"><b>Retrouvez nous sur les reseaux sociaux</b></h1>

            <div className='row text-center my-5'>
                <div class="d-flex w-100 mt-5 justify-content-around border-width-2">
                    <a target='_blank' rel="noreferrer" href={socials.facebook} className="social-icon" style={{ color: '#4267B2' }} >
                        <i class="fa-brands fa-square-facebook"></i>
                    </a>

                    <a target='_blank' rel="noreferrer" href={socials.instagram} className="social-icon">
                        <i class="fa-brands fa-instagram" style={{ color: '#E1306C' }}></i>
                    </a>
                    <a target='_blank' rel="noreferrer" href={socials.telegram} className="social-icon" style={{ color: '#1DA1F2' }}>
                        <i class="fa-brands fa-telegram"></i>
                    </a>
                    <a target='_blank' rel="noreferrer" href={socials.youtube} className="social-icon" style={{ color: '#FF0000' }}>
                        <i class="fa-brands fa-youtube"></i>
                    </a>

                    <a target='_blank' rel="noreferrer" href={socials.twitter} className="social-icon" style={{ color: '#1DA1F2' }}>
                        <i class="fa-brands fa-twitter"></i>
                    </a>

                </div>
            </div>
        </div>
    )
}

export default Contact;
import axios from "axios";
import { BASE_APP_URL, GET_ARTICLES_URL, GET_PAIEMENT_URL, GET_PAYS_URL,GET_ARTICLES_RECENTS_URL,GET_PROMOTED_BOOKMAKERS_URL,GET_BOOKMAKERS_URL,GET_PRONOSTICS_URL, GET_ARTICLE_DETAILS_URL, SEND_CONTACT_FORM, GET_BOOKMAKERS_BYPAYS_URL, GET_BOOKMAKERS_BYMODE_URL } from "./url";

const client = axios.create({
    baseURL: BASE_APP_URL,
});

export async function getPays(){
    const response = await client.get(GET_PAYS_URL);
    return response;
}

export async function getArticles() {
    const response = await client.get(GET_ARTICLES_URL);
    return response;
}

export async function getArticlesPage(page) {
    const response = await client.get(GET_ARTICLES_URL);
    return response;
}

export async function getPaiementsMethods() {
    const response = await client.get(GET_PAIEMENT_URL);
    return response;
}

export async function getRecentsArticle() {
    const response = await client.get(GET_ARTICLES_RECENTS_URL);
    return response;
}

export async function getPromotedBookmaker() {
    const response = client.get(GET_PROMOTED_BOOKMAKERS_URL);
    return response;
}

export async function getBookmakers() {
    const response = await client.get(GET_BOOKMAKERS_URL);
    return response;
}

export async function getBookmakersByPays(name) {
    const response = await client.get(GET_BOOKMAKERS_BYPAYS_URL+`${name}`);
    return response;
}

export async function getBookmakersByMode(name) {
    const response = await client.get(GET_BOOKMAKERS_BYMODE_URL+`${name}`);
    return response;
}

export async function getUniqueBookmakers(slug) {
    const response = await client.get(GET_BOOKMAKERS_URL+`/read/${slug}`);
    return response;
}

export async function getPronostics() {
    const response = await client.get(GET_PRONOSTICS_URL);
    return response;
}

export async function getArticleDetails(slug) {
    const response = await client.get(GET_ARTICLE_DETAILS_URL+slug);
    return response;
}

export async function sendContactForm(values) {
    const response = await client.post(SEND_CONTACT_FORM,values);
    return response;
}
import * as React from 'react'
import MiniBonus from '../MiniBonus/MiniBonus';
import Souscription from "../../assets/images/1win.jpg"
import { Link } from 'react-router-dom';
import { getPaiementsMethods } from '../../services/client';
import { PaiementMethod } from '../../models/PaiementMethod';



const RightSide = () => {

    const [paiements, setPaiements] = React.useState([]);

    React.useEffect(() => {
        getPaiementsMethods().then(result => {
            setPaiements(result.data);
        })
    }, [])

    const listPaiements = paiements.map((item) => {
        return (
            <tr  key={item.id}>
                <Link className='special-link' to={'/bookmakers-paiements/' + item.slug.toLowerCase().replace(' ','-')}>
                    <td style={{width: '30%'}}>
                        <img className='payment-img' alt={item.nom + ' Paiement'} src={item.logo} />
                    </td>
                    <td style={{width: '70%'}}>{item.nom}</td>
                </Link>
            </tr>
        );
    });

    return (
        <div>
            {/*  <img src={Logo} style={style.logoLeft} alt='logo' /> */}
            <h6 className="text-center text-uppercase"><b> Les sites de  paris proposant les paiements</b></h6>
            <table class="table mt-4 align-middle" style={{minHeight: '400px'}} align='center' border={0}>
                <tbody>
                    {listPaiements}
                </tbody>
            </table>
            <div id='pubActive' >
                <a href={'https://1wyxza.top/?open=register#rxx0'} target='_blank' rel="noreferrer">
                    <img className='img-thumbnail w-100' src={Souscription} alt={'Pub Inscription'} style={{transform: 'scale(1)'}}/>
                </a>
            </div>

            <div style={{ maxWidth: '100%' }}>
                <MiniBonus />
            </div>
        </div>
    )
}

export default RightSide;
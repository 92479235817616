import { createSlice } from '@reduxjs/toolkit';

export const bonusSlice = createSlice({
  name: 'bonus',
  initialState: {values: []},
  reducers: {
    setBonus: (state,action) => {
      state.values = action.payload;
      return state;
    }
  },
});

// this is for dispatch
export const { setBonus } = bonusSlice.actions;

// this is for configureStore
export default bonusSlice.reducer;
import * as React from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, Link } from "react-router-dom";
import { getArticles } from "../services/client";
import FondBlog from "../assets/images/fond_blog_blanc.png"

function Articles(props) {



  const bonus = useSelector((state) => state.bonus.values);
  const [loading, setLoading] = React.useState(false)

  const [articles, setArticles] = React.useState([])


  const [pagination, setPagination] = React.useState({})



  const nextPage = () => {
    setLoading(true);
    fetch(pagination.next_page_url).then(result => {
      setArticles(articles.concat(result.data.data));
    })
    setLoading(false);
  }




  React.useEffect(() => {
    getArticles().then(result => {
      setPagination(result.data);
      setArticles(result.data.data);
    }).catch(err => console.log(err.message))
  }, [])



  const [sublink, setSubLink] = React.useState(false)


  const location = useLocation();

  React.useEffect(() => {
    let path = location.pathname.substring(1).split('/');
    if (path.length > 1) {
      setSubLink(true);
    } else {
      setSubLink(false);
    }
  }, [location])

  return (
    <div className="mt-3"  >
      <div>
        <h1 align='center' className="text-uppercase text-sm mb-4"><b>LES MEILLEURS ASTUCES ET ACTU FOOTBALL A NE PAS MANQUER </b> </h1>
        <p className="text-center px-4"> Restez informer  et decouvrer  chaque jours de nouvelles astuces pour mieux placer vos paris sportifs </p>
        <br></br>
        <div class="row ml-2 d-flex align-items-center">
          {articles.map(article => (
            <div class="card col-sm-4" style={{ width: '20em' }}>
              <img class="card-img-top" src={article.image ? article.image : FondBlog} alt="Card cap" />
              <div class="card-body">
                <h5 class="card-title">{article.titre}</h5>
                <p class="card-text text-truncate">{article.description}</p>
                <Link to={'/articles/' + article.slug} className='float-right'>Lire l'article...</Link>
              </div>
              <div class="card-footer">
                <small class="text-muted" style={{ textTransform: 'capitalize' }}>{article.categorie}, Mimo sports, News • {article.updatedAt}</small>
              </div>
            </div>
          ))

          }
          {pagination.next_page_url ? (
            <button onClick={nextPage} className="btn btn-default mt-5">Afficher Plus  D'article ... <span className={` ${loading ? "fa fa-circle" : "fa fa-arrow-down"}`}></span> </button>
          ) : null}
        </div>

      </div>
    </div>
  )
};

export default Articles;
import * as React from "react";
import BonusTable from "../components/BonusTable/BonusTable";
import { useLocation } from 'react-router-dom'
import PronosticItem from "../components/PronosticItem/PronosticItem";
import { getPronostics } from "../services/client";

function Pronostics() {

  const [pronostics,setPronostics] = React.useState([])


  // React.useEffect(() => {
  //   const script = document.createElement("script")

  //   script.src =
  //     "https://telegram.org/js/telegram-widget.js?21"

  //   script.async = true

  //   script.setAttribute('data-telegram-discussion',"universtarx/1614")
  //   script.setAttribute('data-comments-limit',"500")
  //   script.setAttribute('data-colorful',"1")
  //   script.setAttribute('data-color',"E22F38")
  //   script.setAttribute('data-dark-color',"F95C54")

  //   document.querySelector('#chatMessage').appendChild(script)
  // }, [])

  const location = useLocation();

  React.useEffect(() => {
    getPronostics().then(result => {
      setPronostics(result.data);
    }).catch(e => {
        alert(e.message)
    })
}, [])



  const listPronostics = pronostics.map(pronostic => <PronosticItem key={pronostic.id} data={pronostic} />);

  let title = location.pathname.substring(1).replace("-", " ");

  React.useEffect(() => {
    document.title = 'Mimo Sports - ' + title.toLocaleUpperCase() + " du " + new Date().toLocaleDateString();
  })

  return (
    <main className="p-1 p-sm-4 p-lg-5">
      <h1 align='center' className="text-uppercase text-sm mb-4 mt-4"><b>Pronostics FootBall  Du {new Date().toLocaleDateString()}</b></h1>
      <div className="bg-white" style={{minHeight: '500px'}}>
        {listPronostics}
      </div>
      <h2 align='center' className="text-uppercase text-sm mb-4 mt-4"><b>Les Meilleures Bonus</b></h2>
      <BonusTable />

    </main>);
};

export default Pronostics;
//<!--div id="chatMessage"></div-->
import * as React from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import FondBlog from "../assets/images/fond_blog_blanc.png"
import CurrentFondBlog from "../assets/images/fond_blog.png"
import { getArticleDetails } from "../services/client";

function Article() {

  let { slug } = useParams();

  const [article, setArticle] = React.useState({})

  React.useEffect(() => {
    getArticleDetails(slug).then(result => {
      setArticle(result.data)
    }).catch(err => {
      console.log(err);
    })
  }, [slug])


  let liste = ['astuces', 'guide', 'strategie', 'bettingsTips', 'bookmakers', 'youtube']

  return (
    <div>
      <h1 align='center' className="text-uppercase  px-2 mb-2 mt-4 text-center" style={{color: 'rgba(1,1,1,0.6)'}}><b>{article.titre}</b></h1>


      <div className="row my-lg-5 d-flex d-sm-none justify-content-center">
        <img className="img-thumbnail" src={article.image ? article.image : CurrentFondBlog} style={{ height: 'auto', width: '95%' }} alt="postImage" />
      </div>

      <div className="row my-lg-5 d-none d-sm-flex justify-content-center">
        <img className="img-thumbnail" src={article.image ? article.image : CurrentFondBlog} style={{ height: 'auto', width: '40%' }} alt="postImage" />
      </div>

      <article className="article row justify-content-center px-4 px-lg-5">
        <div className="article-description px-lg-5 mb-5">
          <small className="text-theme2">
            {article.categorie} ,  {article.created_at}
          </small>
          <h3 className="my-3 text-uppercase">
            {article.titre}
          </h3>
          <p className="text-justify" dangerouslySetInnerHTML={{__html:article.contenue}}>
          </p>
          <div className="float-right  text-muted">
            <span className="fa fa-solid fa-eye mr-2"></span> {article.lecture}
          </div>
          {article.tags?.map(val => {
            console.log(val);
            return (
              <span className="px-1"><i className="fas fa fa-book fa-solid px-1"></i>{val.nom}</span>
            )
          })}
        </div>
      </article>
    </div >

  )
};

export default Article;
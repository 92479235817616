import * as React from 'react';


const BookmakerItem = (props) => {
    const copiePromo = (val) => {

        // Copy the text inside the text field
        navigator.clipboard.writeText(val.toString());
        console.log(val);
    }

    return (
        <>
            <tr>
                <td align='center'><img src={props.data.logo} width={'80'} height={'60'} alt={'logo-' + props.data.nom.toString().toLowerCase()} /></td>
                <td className="p-3 pb-0">
                    <ul style={{ listStyleType: 'none' }}>
                        {props.data.avantages.split('\n').map(val => {
                            return (
                                <li><i class="mr-2 fas fa-star"></i>{val.substring(1)}</li>
                            )
                        })}
                    </ul>
                </td>
                <td className="p-3 pb-0">
                    <ul style={{ listStyleType: 'none' }}>
                        {props.data.inconvenients.split('\n').map(val => {
                            return (
                                <li><i class="mr-2 fas fa-x"></i>{val.substring(1)}</li>
                            )
                        })}
                    </ul>
                </td>
                <td className="p-0 text-center" style={{ maxWidth: '150px' }}>
                    <a href={"/meilleures-bookmakers/" + props.data.nom.toString().toLowerCase()} class="btn btn-block btn-outline-info"> <i class="fa-sharp fa-solid fa-file"> </i> Details  <i class="fa-sharp fa-solid fa-file"> </i></a>
                    <a href={props.data.lien} target="_blank" rel="noreferrer" class="btn btn-block bg-theme text-light"> <i class="fa-sharp fa-solid fa-gift"> </i> Visiter  <i class="fa-sharp fa-solid fa-gift"> </i></a>
                </td>
            </tr>
            <tr className="bonus-wrap">
                <td colSpan={4} className='justify-content-center align-items-center p-0 m-0'>
                    <p className="text-center">
                        <span>
                            {props.data?.paysDisponible?.map(item => {
                                return (
                                    <i class={"mx-3 fi fi-" + item.drapeau}></i>
                                )
                            })}
                        </span>
                        jusqu'a <b className="text-focus">130 000 FCFA </b> grace au  code promo <span className="box-bordered" onClick={() => { copiePromo(props.data.code_promo) }} title="Copy to clipboard" data-toggle="tooltip" data-placement="top"><b className="text-theme text-right mx-2 text-focus" id="promoCode">{props.data.code_promo}</b><i title="Copier" class="fa-regular fa-copy"></i></span>
                    </p>
                </td>
            </tr>

        </>



    )
}

export default BookmakerItem;

import * as React from 'react'
//import { NavLink } from 'react-router-dom'
//import Banniere from '../../assets/images/baniere-rouge.png'
import Logo from '../../assets/images/logo-mini.svg'
import Bonus from '../../assets/images/boite-cadeau.png'
import style from './style'
import { useDispatch, useSelector } from 'react-redux'
import { setBookmaker } from '../../store/bookmakerSlice'
import { getPromotedBookmaker } from '../../services/client'

const Header = () => {

    const bookmaker = useSelector((state) => state.bookmaker.value);

    const dispatch = useDispatch();

    React.useEffect(() => {
        getPromotedBookmaker().then(result => {
            dispatch(setBookmaker(result.data))
        })
    }, []);



    return (
        <div>
            <div className='bg-light d-none d-lg-flex justify-content-between align-items-center flex-row'>
                <img src={Logo} className='d-inline-block' style={{ height: '30px', width: '150px', position: 'relative', left: '5%' }} alt="" />
                <div className="nav align-items-center justify-content-center flex-3" style={{ position: 'relative', right: '5%' }}>
                    <a className='nav-link disabled' href='##'> La Meilleure application  de paris</a>
                    <a className="btn btn-sm bg-theme text-light align-middle" target='_blanck' href={bookmaker.lien}><i class="fa-sharp fa-solid fa-gift text-uppercase"> </i> Reçoit Ton  Bonus Maintenant <i class="fa-sharp fa-solid fa-gift"> </i></a>
                </div>
                <a href={bookmaker.lien} style={{ position: 'relative', right: '5%' }}>
                    <img src={Bonus} className='d-inline-block flipX' style={{ height: '30px', width: '30px' }} alt="" />
                </a>
            </div>
            <div className=' top-link-pub bg-light d-block d-sm-none d-md-none d-lg-none d-xl-none'>
                <div className="nav align-items-center justify-content-center mobile-pub">
                    <a className="bg-theme text-light align-middle" target='_blanck' href='https://t.me/universdesparieursx'>
                        <i className="fa-sharp fa-solid fa-gift"> </i>
                        <span className='ml-2 mr-2 text-sm text-uppercase text-responsive' style={{ fontSize: '14', fontWeight: '700' }}> Pronostics Gratuit sur telegram </span>
                        <i className="fa-sharp fa-solid fa-gift"> </i>
                    </a>
                </div>
            </div>
            <div style={style.banniere} className='d-flex banniere justify-content-center align-items-center'>
                <div className='w-100  h-100 d-flex flex-column justify-content-center align-items-center  aplat'>
                    {/* <div className='d-flex flex-column justify-content-center align-items-center'>
                        <span>Be Better Player Today</span>
                        <small>Mimo Bettings</small>
                    </div> */}
                </div>
            </div>
        </div>

    )
}

export default Header;
const style ={

banniere: {
    color: 'red',
    height: '250px',
    maxHeight: '250px',
}

}

export default style;
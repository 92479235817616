import * as React from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import FondBlog from "../assets/images/fond_blog_blanc.png"
import CurrentFondBlog from "../assets/images/fond_blog.png"
import { getArticles } from "../services/client";

function Blog() {

  let { categorie } = useParams();

  const [loading, setLoading] = React.useState(false)



  const [articles, setArticles] = React.useState([])

  const [pagination, setPagination] = React.useState({})



  const nextPage = () => {
    setLoading(true);
    fetch(pagination.next_page_url).then(result => {
      setArticles(articles.concat(result.data.data));
    })
    setLoading(false);
  }

  React.useEffect(() => {
    getArticles().then(result => {
      setPagination(result.data);
      setArticles(result.data.data);
    }).catch(err => console.log(err.message))
  }, [])


  let liste = ['astuces', 'guide', 'strategie', 'bettingsTips', 'bookmakers', 'youtube']

  return (
    <div>
      <h1 align='center' className="text-uppercase text-sm mb-4 mt-4"><b>Toutes Les Categories</b></h1>

      <nav class="nav nav-pills nav-fill categories-nav mb-4">
        <NavLink className="nav-link nav-item" activeClassName="active" to="/blog/" exact>Tous</NavLink>
        <NavLink className="nav-link nav-item" activeClassName="active" to="/blog/astuces">Astuces</NavLink>
        <NavLink className="nav-link nav-item" activeClassName="active" to="/blog/guide">Guide</NavLink>
        <NavLink className="nav-link nav-item" activeClassName="active" to="/blog/strategies">Strategies</NavLink>
        <NavLink className="nav-link nav-item" activeClassName="active" to="/blog/bettingsTips">Option de Paris</NavLink>
        <NavLink className="nav-link nav-item" activeClassName="active" to="/blog/bookmakers">Bookmakers</NavLink>
        <NavLink className="nav-link nav-item" activeClassName="active" to="/blog/youtube">Youtube</NavLink>
      </nav>

      <div className="row my-5 px-4">
        <div className=" mr-3 col-sm-5">
          <img className="img-thumbnail" src={articles[0]?.image ? articles[0]?.image : CurrentFondBlog} alt="postImage" />
        </div> 
        <div className="col-sm-6">
          <small className="text-theme2" style={{ textTransform: 'capitalize' }}>
            {articles[0]?.categorie} ,  Mimo Sports ,  {articles[0]?.created_at}
          </small>
          <h4 className="my-3">
            {articles[0]?.titre}
          </h4>
          <p style={{ width: '45% !important' }} className="text-justify">
            {articles[0]?.contenue.substring(0, 400)}
          </p>
          <Link to={'/articles/' + articles[0]?.slug} className='float-right'>Lire l'article...</Link>
        </div>
      </div>

      <div className="row justify-content-center">
        <article className="article d-flex">
          <div className="article-description">

          </div>
        </article>

        <div class="row  d-flex justify-content-center">
          {articles.map(article => (
            <div class="card p-0 mx-2  my-2" style={{ width: '20em' }}>
              <img class="card-img-top" src={article.image ? article.image : FondBlog} alt="Card cap" />
              <div class="card-body">
                <h5 class="card-title">{article.titre}</h5>
                <p class="card-text text-truncate">{article.description}</p>
                <Link to={'/articles/' + article.slug} className='float-right'>Lire l'article...</Link>
              </div>
              <div class="card-footer">
                <small class="text-muted" style={{ textTransform: 'capitalize' }}>{article.categorie}, Mimo sports, News • {article.updatedAt}</small>
              </div>
            </div>
          ))

          }
          {pagination.next_page_url ? (
            <button onClick={nextPage} className="btn btn-default mt-5">Afficher Plus  D'article ... <span className={` ${loading ? "fa fa-circle" : "fa fa-arrow-down"}`}></span> </button>
          ) : null}
        </div>
      </div>
    </div >

  )
};

export default Blog;
import *  as React from 'react'
import { useSelector } from 'react-redux';


const BonusTable = () => {


    const bonus = useSelector((state) => state.bonus.values);

    const copiePromo = (val) => {

        // Copy the text inside the text field
        navigator.clipboard.writeText(val.toString());
        console.log(val);
    }

    return (
        <table border={1} cellspadding="2" cellPadding={2} className="table-bonus table-responsive-sm table text-center  table-center justify-content-center">
            <thead>
                <tr className="text-theme2">
                    <th colSpan={6}>
                        <i class="fa-sharp fa-solid fa-gift mx-3 text-uppercase"> </i>
                        PROFITEZ DE NOS INCROYABLES BONUS
                        <i class="fa-sharp fa-solid fa-gift mx-3"> </i></th>
                </tr>
                <tr>
                    <th>Bookmakers</th>
                    <th>Evaluations</th>
                    <th>Bonus</th>
                    <th>Code Promo</th>
                    <th>Lire L'article</th>
                    <th>Visiter</th>
                </tr>
            </thead>
            <tbody>
                {bonus.map(line => {
                    return (
                        <tr className="align-middle" style={{ verticalAlign: 'center' }}>
                            <td>
                                <img class="card-img-top align-middle mb-2" style={{ width: '100px', height: '70px',border: "1px solid #eee",padding:'1%  10%', verticalAlign: 'center' }} src={line.logo} alt="Card cap" /><br />
                            </td>
                            <td className="align-middle">
                                <span className="text-gold d-flex justify-content-center">
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-regular fa-star"></i>
                                </span>
                            </td>
                            <td className="align-middle">{line.offre_bienvenue} $</td>
                            <td className="align-middle"><span className="box-bordered" onClick={() => { copiePromo(line.code_promo) }} title="Copy to clipboard" data-toggle="tooltip" data-placement="top"><b className="text-theme text-right mx-2 text-focus" id="promoCode">{line.code_promo}</b><i title="Copier" class="fa-regular fa-copy"></i></span></td>
                            <td className="align-middle">
                                <a href="##">
                                    Lire Plus..
                                </a>
                            </td>
                            <td className="align-middle">
                                <a className="btn btn-danger btn-block btn-theme" target="_blank" rel="noreferrer" href={line.lien}>Voir le site</a>
                            </td>
                        </tr>
                    )
                })

                }

            </tbody>
        </table>
    )
}

export default BonusTable;
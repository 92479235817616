import * as React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from "../../assets/images/logo-mini.svg"

const Footer = () => {

    const socials = useSelector((state) => state.socials);


    return (
        <footer className='footer'>
            <div className='footer-top px-4 py-3 row  align-items-center'>
                <div className='d-flex flex-column col-sm-7 justify-content-center'>
                    <img src={Logo} className="align-self-center mb-3" alt="Logo Mimo" width={60} height={60} />
                    <p className='text-justify align-self-center text-sm text-responsive col-sm-6'>
                        Nous fournissons à nos utilisateurs des outils gratuits, des statistiques et une analyse des matchs de football du monde entier. De plus, nous donnons des conseils quotidiens de haute qualité fournis par notre équipe d'analystes professionnels et d'experts. </p>
                    <span className='d-flex flex-row mb-4  justify-content-center'>
                        <a target='_blank' rel="noreferrer" href={socials?.facebook} style={{ color: '#4267B2' }} >
                            <i class="fa-brands fa-square-facebook"></i>
                        </a>

                        <a target='_blank' rel="noreferrer" href={socials.instagram}>
                            <i class="fa-brands fa-instagram" style={{ color: '#E1306C' }}></i>
                        </a>

                        <a target='_blank' rel="noreferrer" href={socials.youtube} style={{ color: '#FF0000' }}>
                            <i class="fa-brands fa-youtube"></i>
                        </a>

                        <a target='_blank' rel="noreferrer" href={socials.twitter} style={{ color: '#1DA1F2' }}>
                            <i class="fa-brands fa-twitter"></i>
                        </a>
                        <a target='_blank' rel="noreferrer" href={socials.telegram} style={{ color: '#1DA1F2' }}>
                            <i class="fa-brands fa-telegram"></i>
                        </a>
                    </span>
                </div>
                <div className='d-flex col-sm-5 justify-content-around'>
                    <div>
                        <h5 className='text-muted text-uppercase mr-3'>ASTUCES  DE GAGNANTS</h5>
                        <ul className='mr-2 footer-link'>
                            <li className='nav-item'>
                                <a href='##'>Comment Gagnez les paris (+/- x But)? </a>
                            </li>
                            <li className='nav-item'>
                                <a href='##'>Comment Obtenir jusqu'a 130 000 de Bonus ?</a>
                            </li>
                            <li className='nav-item'>
                                <a href='##'>Pourquoi Betwinner est le meilleurs Bookmakers  ?</a>
                            </li>
                            <li className='nav-item'>
                                <a href='##'>Les Meilleures  Pronostiquers  d'Afrique ?</a>
                            </li>
                        </ul>

                    </div>
                    <div>
                        <h5 className='text-muted'>PARTNERS</h5>
                        <ul className='text-capitalize footer-link'>
                            <li className='nav-item'>
                                <a href='##'>Fifa Prono Tips </a>
                            </li>
                            <li className='nav-item'>
                                <a href='##'>Universtar Pronostics</a>
                            </li>
                            <li className='nav-item'>
                                <a href='##'>Expert Prono</a>
                            </li>
                            <li className='nav-item'>
                                <a href='##'>Empire Bet</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='social'>
                <div class="row text-center py-2 d-flex align-items-center  justify-content-center">
                    <span className='text-muted col-sm-6'>© 2020-2022 Mimo Sports - Be Better Player  Today. All rights reserved.</span>
                    <ul className='nav  d-flex flex-row justify-content-center footer-nav col-sm-6'>
                        <li className='nav-item'>
                            <a className='nav-link' href='/'>Acceuil</a>
                        </li>
                        <li className='nav-item'>
                            <a className='nav-link' href='/bookmakers'>Bookmakers</a>
                        </li>
                        <li className='nav-item'>
                            <a className='nav-link' href='/pronostics'>Pronostics</a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer >
    )

}

export default Footer;
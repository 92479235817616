import * as React from "react";
import { useParams } from "react-router-dom";
import Register from '../../assets/images/register.png'
import { getUniqueBookmakers } from "../../services/client";

const OneXbet = (props) => {
  const copiePromo = (val) => {

    // Copy the text inside the text field
    navigator.clipboard.writeText(val.toString());
    console.log(val);
  }

  const {slug} = useParams();
  const name = slug;

  const [bookmaker, setBookmaker] = React.useState({})

  React.useEffect(() => {
    getUniqueBookmakers(slug).then(result => {
      setBookmaker(result.data);
    }).catch(err => console.log(err.message));
    console.log("blacckstar voici  la "+slug);
  }, [])


  return (
    <div>
      <div className=' col-sm-12'>
        <h1 align='center' className="text-uppercase text-sm mb-1"><b>Decouvrez le Bookmaker 1xbet </b> </h1>
        <div class="p-2 row px-0">
          <div className="d-flex bg-light  text-justify px-5 flex-column justify-content-center align-items-center col-sm-4">
            <div className=" img-thumbnail m-3  d-flex justify-content-center" style={{ width: '10em', height: '10em' }}>
              <img style={{ width: '8em', borderRadius: '8em' }} src={bookmaker?.logo} alt="Card cap" />
            </div>
            <span className="box-bordered" onClick={() => { copiePromo(props.data) }} title="Copy to clipboard" data-toggle="tooltip" data-placement="top">CODE  PROMO  : <b className="text-theme text-right mx-2 text-focus" id="promoCode">MIMO</b><i title="Copier" class="fa-regular fa-copy"></i></span>

            <p class="card-text pt-3 clearfix" dangerouslySetInnerHTML={{ __html: bookmaker?.bonus }}></p>
            <a href={bookmaker?.lien} class="btn btn-sm bg-theme mb-3 text-light text-focus float-right"> <i class="fa-sharp fa-solid fa-gift"> </i> Recevoir {bookmaker?.offre_bienvenue}$ de Bonus  <i class="fa-sharp fa-solid fa-gift"> </i></a>
          </div>
          <div className="px-3 clearfix col-sm-7">
            <table className="table table-striped">
              <tbody>
                <tr>
                  <th> Annee de creation :</th>
                  <td> {bookmaker.date_creation}  </td>
                </tr>

                <tr>
                  <th> Langue :</th>
                  <td> {bookmaker.langue}  </td>
                </tr>

                <tr>
                  <th> Siege  Social :</th>
                  <td> {bookmaker.siege}  </td>
                </tr>

                <tr>
                  <th> Mise Minimal :</th>
                  <td> {bookmaker.mise_minimum}$  </td>
                </tr>

                <tr>
                  <th> Gains Maximal :</th>
                  <td> {bookmaker.gains_maximum}$  </td>
                </tr>

                <tr>
                  <th> Plateformes :</th>
                  <td> {bookmaker.plateforme}  </td>
                </tr>

                <tr>
                  <th> Disponibilite :</th>
                  <td> 24/7  </td>
                </tr>


                <tr>
                  <th> Contact  service client :</th>
                  <td>  <a href={'mailto:'+bookmaker.service_client} target='_blank' rel="noreferrer">{bookmaker.service_client}</a>  </td>
                </tr>

                <tr>
                  <th> Note des Utilisateurs :</th>
                  <td> <span className="text-gold align-middle">
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-regular fa-star"></i>
                  </span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <h1 align='center' id="inscription1xbet" className="text-uppercase text-sm"><b>Ce  Qu'il Faut savoir sur {name} </b> </h1>

        <p>
          {name} figure  dans le top 10 des bookmakers, il a réussi à capter l’attention des joueurs et continue de grimper dans les rangs des paris. Les plaintes ont diminué au fil du temps,
          et malgré la licence NV, il s’est avéré être un livre prospère, stable et fiable. Je trouve toujours la version de bureau déroutante, ce qui est généralement le cas pour toutes
          les marques de plateforme BetB2B. D’un autre côté, cependant, la version mobile et l’application ont sauvé {name} de la journée. Honnêtement, en ce qui concerne le produit,
          il n’y a rien à détester. Les cotes sur les gagnants des matchs et le handicap asiatique sont très bonnes, vous pouvez placer des paris sur leurs échanges de paris et les retraits
          sont très rapides. Non seulement cela, mais vous pouvez également trouver des services de diffusion en direct et de nombreuses autres fonctionnalités telles que Bet Builder et Cashout.
          Cependant, Quickbet a été un léger raté, tout comme l’absence de Sofort, Trustly et Paypal. Aussi, je ne peux pas ne pas mentionner la médiocrité et le service client trop agressif – dans
          la plupart des cas – ainsi que le fait que le service des risques est prompt à limiter les joueurs. Pourtant, {name} me semble être un gagnant, et cela vaut certainement la peine.
        </p>

        <div className=" my-3 text-center">
          <h1 align='center' id={`inscription${name}`} className="text-uppercase text-sm"><b>Comment  S'inscrire sur {name} ?</b> </h1>
          <small style={{ tabSize: '2em' }}>Comment obtenir jusqu'a 130 000Fcfa de bonus </small>
        </div>
        <div className="row">
          <div className="col-sm-6 p-5">
            <div border='0' className="etape-inscriptiom">
              <div className="d-flex py-3">
                <span className="number"><span className="img-thumbnail text-light">1</span></span>
                <span>Telecharger l'application Mobile en <a className="text-uppercase" href={bookmaker.application_mobile ? bookmaker.application_mobile : bookmaker.lien}> Cliquant Ici</a> ou <a className="text-uppercase" href={bookmaker.lien}> Visiter le site</a>.</span>
              </div>

              <div className="d-flex py-3">
                <span className="number"><span className="img-thumbnail text-light">2</span></span>
                <span>Cliquez sur inscription et selectionnez l'option <b className="text-focus">INSCRIPTION COMPLETE</b>.</span>
              </div>

              <div className="d-flex py-3">
                <span className="number"><span className="img-thumbnail text-light">3</span></span>
                <span>Remplissez correctement vos informations et  dans la case <b>CODE PROMO</b> saisissez <b className="text-focus">{bookmaker.code_promo}</b> puis valider.</span>
              </div>

              <div className="d-flex py-3">
                <span className="number"><span className="img-thumbnail text-light">4</span></span>
                <span>Cliquez sur <b className="text-uppercase">envoyer le code</b> pour recevoir le  code de validation.</span>
              </div>

              <div className="d-flex py-3">
                <span className="number"><span className="img-thumbnail text-light">5</span></span>
                <span>Saisissez le code de validation  recu  par SMS et  valider pour <b className="text-uppercase">confirmer votre compte</b>.</span>
              </div>

              <div className="d-flex py-3">
                <span className="number"><span className="img-thumbnail text-light">6</span></span>
                <span>Valider et <b className="text-uppercase">rechargez votre compte de 1000FCFA</b> Minimum soit 2$ pour  recevoir votre bonus immediatement.</span>
              </div>


            </div>
          </div>
          <div className="col-sm-6 p-5">
          <iframe width="460" height="540" src={bookmaker.video_presentation} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          {/*   <video  className="bg-light" style={{ height: '100%', width: '100%' }} src={bookmaker.video_presentation} alt={`Inscription  ${name}`} />
          */}</div> 
        </div>


        {/* <div className="w-100 bg-theme month-bonus">
          <div className="row px-5 py-4">

            <div className="col-sm-5 pt-2 text-center align-items-center  d-flex flex-column">
              <h2 className="text-light pb-3 text-uppercase"><b> Enfin Un Pronostiqueur Gratuit !!</b></h2>
              <p className="text-light text-justify">
                Le Meilleur Bookmaker d'apres les sondage Mensuel, Obtenez Un bonus  de bienvenue incroyable, 100% de bonus  sur le premier  depot, Un Bonus pour votre anniversaire et une remise allant jusqu'a 20% en fin de mois sur vos pertes en inserant le code promo <b>MIMO</b> pendant votre inscription.
              </p>
              <div className="d-flex my-2">
                <a className="btn btn-sm text-right bg-light text-dark" target='_blanck' href='https://1xbetcm.com'><i class="fa-sharp fa-solid fa-lightbulb text-dark text-uppercase"> </i> <span className="mx-1 text-uppercase">Visiter Le site</span> <i class="fa-sharp fa-solid fa-lightbulb text-dark text-uppercase"> </i> </a>
              </div>
            </div>
            <div className="col-sm-7 d-flex justify-content-center align-items-center">
              <img src={PUBUNIVERSTAR} className="mx-2" style={{ height: '250px', maxHeight: '250px', color: 'white' }} alt="logo-bookmaker-du-mois" />
            </div>

          </div>

        </div>
 */}

        <p>
          <h4> Qu’est-ce qu’un gagnant-gagnant?</h4>
          <p>
            Avant d’énumérer tous les avantages et de vous montrer notre revue {name}, il est important de comprendre les origines de ce bookmaker en pleine expansion. En fait, {name} est une marque de paris sportifs fondée en {bookmaker.date_creation}. Cet établissement de jeux en ligne est situé à Chypre et appartient à Marakit Holdings. Même si {name} est un nouveau venu dans l’espace des jeux d’argent en ligne, il offre déjà de nombreux avantages. C’est pourquoi il compte déjà plus de 450 000 utilisateurs. Mais qu’est-ce qu’il a de si spécial ? Découvrez les réponses ci-dessous.
          </p>
          <h4>
            {name} : Simple et facile à utiliser
          </h4>
          <p>
            La facilité d’utilisation est un facteur important pour les bookmakers en ligne. Aussi triviale que cela puisse paraître, l’expérience utilisateur est le principal atout de {name}. En fait, l’interface de ce bookmaker est très conviviale. Cela facilite grandement la navigation du joueur, en particulier le jeu lui-même. De l’inscription {name} aux codes promo {name}, vous trouverez un accès très simple. D’autre part, le site Web de l’agence a un design réactif unique. En d’autres termes, {name} Mobile est une véritable innovation.

          </p>

          <h4>Excellent classement du jeu</h4>
          <p>
            Saviez-vous que {name} peut offrir de bonnes cotes pour vos paris ? En effet, chaque fois que vous gagnez, vous obtenez une plus grande part des bénéfices. C’est une opportunité à ne pas manquer. De plus, c’est un facteur important car des cotes plus faibles signifient que vous devez gagner plus de paris pour gagner beaucoup d’argent. Avec {name}, vos chances de gagner plus d’argent sont en effet plus élevées. Contrairement aux autres bookmakers traditionnels ordinaires, il a plus à vous offrir.

          </p>
          <h4>
            Plusieurs méthodes de paiement
          </h4>
          <p>
            Le mode de paiement rend également la réputation du bookmaker à la hauteur de son nom. Avec le moyen de paiement {name}, vous pouvez effectuer toutes sortes de transactions financières, que vous utilisiez un portefeuille virtuel ou des crypto-monnaies. Voulez-vous empocher vos gains plus rapidement et en toute sécurité partout dans le monde ? Vous souhaitez un retrait simple et sans tracas ? Choisissez {name} et misez sur la fiabilité des moyens de paiement et des transactions financières.

          </p>
          <h4>
            Bonus généreux et promotions fréquentes
          </h4>
          <p>
            Les bonus et les offres promotionnelles sont également les facteurs les plus importants lorsque les joueurs choisissent un bookmaker. Avec {name}, vous ne serez pas déçu. Des bonus {name} et des codes promotionnels {name} vous seront fournis. En fait, si vous vous inscrivez auprès de ce bookmaker et effectuez votre premier dépôt, vous pouvez obtenir des bonus incroyables. Même après quelques mois d’adhésion, vous recevrez toujours suffisamment d’offres promotionnelles de {name}. C’est définitivement l’exception.

          </p>
          <h4>
            Plusieurs jeux sont disponibles sur {name}
          </h4>
          <p>
            {name} propose une large gamme d’activités et de jeux en ligne, et vous avez un énorme potentiel de gain à chaque fois que vous placez un pari. En fait, ce bookmaker couvre plusieurs événements sportifs à travers le monde. Avec l’option de diffusion en direct, vous pouvez être sûr d’une expérience de jeu unique. Vous n’avez pas besoin d’attendre une émission de télévision pour voir les résultats de vos paris. Vous pouvez obtenir tout ce que vous devez savoir sur le site Web lui-même.

          </p>
          En dépit d’être un ajout récent au monde des jeux d’argent en ligne, {name} a tout le potentiel pour devenir un géant. Avec divers bonus et offres promotionnelles, différents modes de paiement, ce bookmaker offre une vraie chance de gagner. Vous voulez une expérience de jeu unique, rejoignez ces milliers de membres dès maintenant.
        </p>

        <h1 align='center' className="text-uppercase text-sm my-3"><b>Avantages et  Inconveniants</b> </h1>
        <div className="row mx-0">
          <div className="col-sm-6 bg-theme text-light  p-4">
            <h3 align='center'><b style={{ fontSize: 23 }}>Avantages</b></h3>

            <ul style={{ listStyleType: 'none' }}>
              {bookmaker?.avantages?.split('\n').map(val => {
                return (
                  <li><i class="mr-2 fas fa-x"></i>{val.substring(1)}</li>
                )
              })}
            </ul>
          </div>
          <div className="col-sm-6 bg-dark text-light p-4">
            <h3 align='center'><b style={{ fontSize: 23 }}>Inconveniant</b></h3>
            <ul style={{ listStyleType: 'none' }}>
              {bookmaker?.inconvenients?.split('\n').map(val => {
                return (
                  <li><i class="mr-2 fas fa-x"></i>{val.substring(1)}</li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
      <br></br>
    </div>

  )
};

export default OneXbet;
import * as React from 'react'
import { Link } from 'react-router-dom';
import CurrentFondBlog from "../../assets/images/fond_blog.png"
import Pub1xbet from "../../assets/images/pub.gif"
import { getPays, getRecentsArticle } from '../../services/client';



const LeftSide = () => {

    React.useEffect(() => {
        const script = document.createElement("script")
    
        script.src =
          "https://telegram.org/js/telegram-widget.js?21"
    
        script.async = true

        script.setAttribute('data-telegram-post',"universtarx/22")
        script.setAttribute('data-userpic',"false")
        script.setAttribute('data-width',"100%")
        script.setAttribute('data-color',"E22F38")
        script.setAttribute('data-dark-color',"F95C54")
        script.setAttribute('style',"")
    
        document.querySelector('#telegramMessage').appendChild(script)

      }, [])

    const [allPays, setAllPays] = React.useState([]);
    const [articles, setArticles] = React.useState([]);

    React.useEffect(() => {
        getPays().then(result => {
            setAllPays(result.data);
        }).catch(e => {
            alert(e.message)
        })

        getRecentsArticle().then(result => {
            setArticles(result.data.data);
        })
    }, [])

    const listPays = allPays.map((item) => {
        return (
            <tr>
                <a className='special-link' style={{ display: 'flex' }} href={'/bookmakers-disponible/'+item.nom.toLowerCase()}>
                    <td style={{ width: '30%' }}><span class={"fi fi-" + item.drapeau}></span></td>
                    <td style={{ width: '70%' }}>{item.description}</td>
                </a>
            </tr>
        );
    });


    const listArticles = articles.map((article) => {
        return (
            <li className='article-mini clear-fix row  px-3 justify-content-center' style={{listStyleType: 'none'}}>
                    <img className='col-4' src={CurrentFondBlog} alt={'astuce'} />
                    <p className='col-8 align-middle'>
                       {article.titre}  <br />
                        <a href={'/articles/' + article.slug} className='float-right'>Lire l'article...</a>
                    </p>
            </li>
        );
    });

    return (
        <section>
            <h6 className="text-center text-uppercase"> <b>Les Meilleures  Astuces pour  debutants</b></h6>
            <div>
                <ul className='article-widget'  style={{minHeight: '400px'}}>
                    {listArticles}
                </ul>
            </div>
            <div>
                <h6 className="text-center text-uppercase"> <b>Les Meilleures sites  de paris par  Pays</b></h6>
                <table class="table mt-4" border={0}>
                    <tbody>
                        {listPays}
                    </tbody>
                </table>
            </div>
            <a href='https://affpa.top/L?tag=d_882729m_2896c_&site=882729&ad=2896' rel="noreferrer" target="_blank">
                <img className='img-thumbnail w-100' src={Pub1xbet} alt={'Pub 1xbet'} />
            </a>
            <div id='telegramMessage' className='row'>
                
            </div>
        </section>
    )

}

export default LeftSide;
import * as React from "react"
import { useSelector } from "react-redux";

import BonusItem from "../components/BonusItem/BonusItem";


function Bonus(props) {



  const bonus = useSelector((state) => state.bonus.values);


  const listBonus = bonus.map(item => <BonusItem key={item.id} data={item} />);

  return (
    <div>
      <h1 align='center' className="text-uppercase text-sm mb-4 mt-4"><b>Les Meilleures Bonus Offerts</b></h1>
      <div>
          <div className="d-flex row justify-content-center">
            {listBonus}
          </div>
        </div>
    </div>
  );
};

export default Bonus;
import { createSlice } from '@reduxjs/toolkit';

export const bookmakersSlice = createSlice({
  name: 'bookmaker',
  initialState: {value: {name: '1xbet',code_promo: 'MIMO'}},
  reducers: {
    setBookmaker: (state,action) => {
      state.value = action.payload;

      return state;
    }
  },
});

// this is for dispatch
export const { setBookmaker } = bookmakersSlice.actions;

// this is for configureStore
export default bookmakersSlice.reducer;
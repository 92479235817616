import * as React from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, Link } from "react-router-dom";
import { getPays } from "../services/client";

function BookmakersDisponibles(props) {



  const bonus = useSelector((state) => state.bonus.values);


  const copiePromo = (val) => {

    // Copy the text inside the text field
    navigator.clipboard.writeText(val.toString());
    console.log(val);
  }




  const [sublink, setSubLink] = React.useState(false)

  const [pays, setPays] = React.useState([])


  const location = useLocation();
  React.useEffect(() => {
    getPays().then(result => {
      setPays(result.data);
    }).catch(err => console.log(err.message))
  }, [])


  React.useEffect(() => {
    let path = location.pathname.substring(1).split('/');
    if (path.length > 1) {
      setSubLink(true);
    } else {
      setSubLink(false);
    }
  }, [location])

  return (
    <div className="mt-3">
      <div>
        <h1 align='center' className="text-uppercase text-sm mb-4"><b>DANS  QUEL PAYS  VOUS TROUVER VOUS ? </b> </h1>
        <p className="text-justify px-4">
          Nous avonts regrouper pour vous, tous les meilleures  bookmakers de chaque pays, etudier  leurs offres  et opter pour le bookmakers qui vous correspons le mieux
          </p>
          <br></br>
          <div class="row d-flex  ml-4">
            {pays.map(item => (
              <div class="row col-sm-6 py-5">
                <span style={{transform: 'scale(1.2)'}} class={"col-6 fi fi-" + item.drapeau}></span>
                <div className="col-6" class="card-body">
                  <h5 class="card-title px-2">{item.description}</h5>
                  <Link to={'/bookmakers-disponible/'+item.nom.toLowerCase()} className='text-center  px-2'>Consulter</Link>
                </div>
              </div>
            ))

            }
          </div>

      </div>
    </div>
  )
};

export default BookmakersDisponibles;
export const  BASE_APP_URL  = 'https://195.15.213.42/api/v1.0'; //'http://127.0.0.1:8085/api/v1.0' //'http://195.15.213.42:8085/api/v1.0';//'http://127.0.0.1:8000/api';

export const  GET_PAIEMENT_URL  = '/paiements';
export const  GET_ARTICLES_URL  = '/articles';
export const  GET_ARTICLES_PAGE_URL  = '/articles?page=';
export const  GET_ARTICLES_RECENTS_URL  = '/articles/recents';
export const  GET_PAYS_URL  = '/pays';
export const  GET_BOOKMAKERS_URL  = '/bookmakers';
export const  GET_BOOKMAKERS_BYPAYS_URL  = '/bookmakers/pays/';
export const  GET_BOOKMAKERS_BYMODE_URL  = '/bookmakers/mode-paiement/';
export const  GET_PRONOSTICS_URL  = '/pronostics/journaliers';
export const GET_PROMOTED_BOOKMAKERS_URL = '/bookmakers/promoted';
export const GET_ARTICLE_DETAILS_URL = '/articles/details/';

export const SEND_CONTACT_FORM = '/contacts';

import * as React from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import BookmakerItem from "../components/BookmakerItem/BookmakerItem";
import BookmakerItemMobile from "../components/BookmakerItem/BookmakerItemMobile";
import { getBookmakersByMode } from "../services/client";

function BookmakersPaiement(props) {



const [bonus, setBonus] = React.useState([])






  const location = useLocation();

  React.useEffect(() => {
    let path = location.pathname.split('/');
    getBookmakersByMode(path[path.length-1]).then(result => {
      setBonus(result.data);
      console.log(result.data);
    });
  }, [location])

  return (
    <div className="mt-3"  >
        <div>
          <h1 align='center' className="text-uppercase text-sm mb-4"><b>Classement des Bookmakers en {new Date().getFullYear()} </b> </h1>
          <p className="text-justify px-4">
            Durant les 10 dernieres annee nous avont  vue emmerger des bookmakers de  tout horizon, tous proposant des multiple avantage et inconveniant, nous avont mener une etude sur les principaux bookmaker du moments afon de dresser pour vous cette liste non exhaustive.
            des bonus de bienvenu incroyable,  des plateformes variant du web au mobile, en fonction de votre pays vous aurez accces a diver mode de paiement, il deviens simble pour vous de faire des depot et des retrait sur vos compte instantanement. peut  importe votre methode
            de recharge favorite  (cryptomonaie, carte de credit, virement bancaire , portefeuille electronique, mobile money) nous avont le bookmaker qu'il vous  faut.
            Bien  conscient que vous meriter le meilleure, nous  avont selectionnez pour vous les meilleures bookmakers du moments.
          </p>
          <p className="text-justify px-4">
            Il est neanmois important  que vous choisissiez le Bookmaker qui vous ccorrespondent le mieux et vous procure  le plus d'avantage possible, aussi bien  au niveau des  transaction qu'au niveau  des paris.
          </p>
          <br></br>

          <table className="col-lg-12 align-self-center d-none d-lg-block">
            <thead className="text-center  mb-5">
              <tr className="bg-theme text-light">
                <th style={{ width: '12%' }} >Bookmakers</th>
                <th style={{ width: '40%' }} >Avantages</th>
                <th style={{ width: '38%' }} >Inconvenients</th>
                <th style={{ width: '15%' }} >Bonus  Offerts</th>
              </tr>
            </thead>
            <tbody>
              {bonus.map(val => (
                <BookmakerItem key={val.id} data={val} />
              ))}
            </tbody>
          </table>
          <div className="d-block d-lg-none">
          {bonus?.map(val => (
                <BookmakerItemMobile key={val.id} data={val} />
              ))}
          </div>
        </div>
    </div>
  )
};

export default BookmakersPaiement;
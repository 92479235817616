import *  as React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getBookmakers } from '../../services/client';
import { setBonus } from '../../store/bonusSlice';


const MiniBonus = () => {

    const bonus = useSelector((state) => state.bonus.values);


    const dispatch = useDispatch();

    React.useEffect(() => {
        getBookmakers().then(result => {
            dispatch(setBonus(result.data))
        })
    }, []);

    const listBonus = bonus?.map((item) => {
        return (
            <tr className="align-middle" style={{ verticalAlign: 'center' }}>
                <td>
                    <img class="" style={{ width: '60%', verticalAlign: 'center'}} src={item.logo} alt="Card cap" /><br />
                </td>
                <td className="align-middle "><b>{item.offre_bienvenue}$</b></td>
                <td className="align-middle">
                    <a className="btn btn-sm btn-danger bg-theme" href={item.lien}>Consulter</a>
                </td>
            </tr>
        );
    });
    return (
        <table border={1} className="table-bonus mini table text-center max-width-100">
            <thead>
                <tr className="text-theme2 text-sm text-uppercase">
                    <th colSpan={6}>
                        <i class="fa-sharp fa-solid fa-gift mx-2 text-uppercase">  </i>
                        TOP 10 Bookmakers
                        <i class="fa-sharp fa-solid fa-gift mx-2">  </i></th>
                </tr>
                <tr>
                    <th>Bookmakers</th>
                    <th>Bonus</th>
                    <th>Site Web</th>
                </tr>
            </thead>
            <tbody>
                {listBonus}
            </tbody>
        </table>
    )
}

export default MiniBonus;

//import { Component } from 'react';
import React from "react";
//import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Blog from "./pages/Blog";
import Home from "./pages/Home";
import Bookmakers from "./pages/Bookmakers";
import Pronostics from "./pages/Pronostics";
import Bonus from "./pages/Bonus";
import NotFound from "./pages/errors/NotFound";
import Post from "./pages/Post";
import Contact from "./pages/Contact";
import OneXbet from "./pages/bookmakers/1xbet";
import OneWin from "./pages/bookmakers/1win";
import Betwinner from "./pages/bookmakers/Betwinner";
import HuitStarz from "./pages/bookmakers/888starz";
import Melbet from "./pages/bookmakers/melbet";
import LineBet from "./pages/bookmakers/linebet";
import BetMomo from "./pages/bookmakers/BetMomo";
import PremierBet from "./pages/bookmakers/PremierBet";
import BetPawa from "./pages/bookmakers/Betpawa";
import BookmakerDetails from "./pages/bookmakers/BookmakersDetails";
import Article from "./pages/Article";
import BookmakersPays from "./pages/BookmakersPays";
import BookmakersPaiement from "./pages/BookmakersPaiement";
import BookmakersPaiements from "./pages/BookmakersPaiements";
import BookmakersDisponibles from "./pages/BookmakersDisponibles";
import Articles from "./pages/Articles";

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="meilleures-bookmakers" element={<Bookmakers />}>
{/*               <Route path="1xbet" element={<OneXbet />} />
              <Route path="1win" element={<OneWin />} />
              <Route path="betwinner" element={<Betwinner />} />
              <Route path="888starz" element={<HuitStarz />} />
              <Route path="melbet" element={<Melbet />} />
              <Route path="linebet" element={<LineBet />} />
              <Route path="betmomo" element={<BetMomo />} />
              <Route path="premierbet" element={<PremierBet />} />
              <Route path="betpawa" element={<BetPawa />} /> */}
              <Route path=":slug" element={<BookmakerDetails />} />
            </Route>
            <Route path="pronostics" element={<Pronostics />} />
            <Route path="blog" element={<Blog />}>
              <Route path=":categorie" element={<Post />} />
            </Route>
            <Route path="articles" element={<Articles />} />
            <Route path="articles/:slug" element={<Article />} />
            <Route path="bookmakers-disponible" element={<BookmakersDisponibles />} />
            <Route path="bookmakers-disponible/:slug" element={<BookmakersPays />} />
            <Route path="bookmakers-paiements" element={<BookmakersPaiements />} />
            <Route path="bookmakers-paiements/:slug" element={<BookmakersPaiement />} />
            <Route path="bonus" element={<Bonus />} />
            <Route path="contact" element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;

import * as React from "react";
import { useParams } from "react-router-dom";

const  Post = () => {

  let { categorie } = useParams();

  return (
    <div>
      <h1 align='center' className="text-uppercase text-sm mb-4 mt-4">{{categorie}}</h1>
    </div>

  )
};

export default Post;
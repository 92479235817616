import * as React from 'react'
import { NavLink } from 'react-router-dom'

const Navbar = () => {


    return (
        <nav className='bg-light shadow-custom navbar navbar-expand-lg p-0 mb-3' style={style.nav}>
            <button class="navbar-toggler" type="button" style={{outline:'none',paddingTop: '3%'}} data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon">
                    <span className='fa-sharp fa-solid fa-bars'></span>
                </span>
            </button>
            <div class="collapse navbar-collapse  p-0 m-0 justify-content-center" id="navbarSupportedContent">
                <ul class="nav navbar-nav ">
                    <li class="nav-item">
                        <NavLink className="nav-link" style={style.link} activeClassName="active" to="/">Acceuil</NavLink>
                    </li>
                    <li class="nav-item">
                        <NavLink className="nav-link" style={style.link} activeClassName="active" to="/pronostics">Pronostics Journaliers</NavLink>
                    </li>
                    <li class="nav-item">
                        <NavLink className="nav-link" style={style.link} activeClassName="active" to="/meilleures-bookmakers">Les Meilleures Bookmakers</NavLink>
                    </li>
                    <li class="nav-item">
                        <NavLink className="nav-link" style={style.link} activeClassName="active" to="/bonus">Bonus</NavLink>
                    </li>
                    <li class="nav-item">
                        <NavLink className="nav-link" style={style.link} activeClassName="active" to="/blog">Blog</NavLink>
                    </li>
                    <li class="nav-item">
                        <NavLink className="nav-link" style={style.link} activeClassName="active" to="/contact">Contact</NavLink>
                    </li>
                </ul>
            </div>

        </nav>
    )
}

const style = {
    nav: {

    },
    link: {
        color: '#222',
    },

}

export default Navbar;
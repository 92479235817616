import { createSlice } from '@reduxjs/toolkit';

export const socialsSlice = createSlice({
  name: 'socials',
  initialState: {facebook: 'https://facebook.com/universtar237',instagram: 'https://instagram.com/universtar237',twitter: 'https://twitter.com/universtar237',telegram: 'https://t.me/universtarx',youtube: 'https://www.youtube.com/channel/UCeGTR9K8jH3SoAIAGGY78Ag'},
  reducers: {
    setSocials: (state,action) => {
      state.values = action.payload;
      return state;
    }
  },
});

// this is for dispatch
export const { setSocials } = socialsSlice.actions;

// this is for configureStore
export default socialsSlice.reducer;

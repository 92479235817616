import * as React from 'react';
import { useSelector } from "react-redux";


const PronosticItem = (props) => {

    const currentBookmaker = useSelector((state) => state.bookmaker.value);

    return (
        <div className="pronostic mb-2 p-1 bg-light">
            <h2 align='center' className=" bg-theme text-light text-focus  text-capitalize py-2 mb-0">{props.data.championnat.nom}</h2>
            <div className="prono  d-flex bg-white align-items-center text-center py-3">
                <table style={{width: '100%'}}>
                    <tr>
                        <td style={{width:'45%'}}> <img src={props.data.equipeLocal.logo} className="club-logo" alt="psg-logo" /></td>
                        <td style={{width:'10%'}}>Vs</td>
                        <td  style={{width:'45%'}}> <img  src={props.data.equipeExterieur.logo} className="club-logo order-sm-2 order-1 align-center" alt="juventus-logo" /></td>
                    </tr>
                    <tr>
                    <td style={{width:'45%'}}> <span style={{ lineHeight: '50px' }} className="club-name">{props.data.equipeLocal.nom}</span></td>
                    <td style={{width:'10%'}}></td>
                    <td style={{width:'45%'}}>  <span style={{ lineHeight: '50px',fontSize: 'small' }} className="club-name order-sm-1 order-2">{props.data.equipeExterieur.nom}</span></td>
                    </tr>
                </table>
            </div>
            <div className="bg-white row d-flex justify-content-around  py-2 align-self-center  mb-0">
                <ul style={{ listStyleType: 'none' }} className="offset-sm-2 col-sm-4 px-3 pronoTips">
                    {props.data.pronostics.map((prono) => {
                        return (
                            <li className='d-flex align-items-center'><i class="mb-1 flex-1 fas fa-star"></i><span className='ml-3'>{prono}</span></li>
                        )
                    })

                    }
                </ul>
                <div className="d-flex justify-content-center align-items-center col-6">
                    <a className="btn bg-theme btn-sm" href={currentBookmaker?.lien}>
                        <span className="text-light"> Placer un pari</span>
                    </a>
                    <span className="fas fa-arrow-right  mx-3"> </span>
                    <img src={currentBookmaker?.logo} width={'80'} height={'50'} alt="logo-bookmaker" />
                </div>
            </div>
        </div>





    )
}

export default PronosticItem;

import * as React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Navbar from "../components/Navbar/Navbar";
import LeftSide from "../components/SideView/LeftSide";
import RightSide from "../components/SideView/RightSide";
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from "react-redux";

//import Logo from "../assets/images/logo-universtar-pronostics.png"

const Layout = ({ navigation }) => {

  const history = useNavigate()
  const location = useLocation();
  const socials = useSelector((state) => state.socials);
  let path = [];

  React.useEffect(() => {
    let path = location.pathname.substring(1).replace("-", " ").split('/');
    let title = '404'
    if (path.length > 1) {
      title = path[path.length - 1].toString();
    } else {
      title = location.pathname.substring(1).replace("-", " ");
    }

    if (title === '') {
      title  = 'Be Better Player Today';
    }

    document.title = 'Mimo Bettings - ' + title.toLocaleUpperCase();
  }, [location])

  React.useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, [history])





  const [loading, setLoading] = React.useState(false);
  const LinkSize = location.pathname.substring(1).replace("-", " ").split('/').length;
  const breadcrumb = location.pathname.substring(1).replace("-", " ").replace("-", " ").split('/').map((title, position, all) => {
    if (position === all.length - 1) {
      return (
        <li class="breadcrumb-item  text-capitalize" aria-current='page'>{title}</li>
      )
    } else {
      return (
        <li class="breadcrumb-item text-capitalize"><a href={'/'+title.replace(" ", "-").toLowerCase()}>{title}</a></li>
      )
    }

  })


  return (
    <div>
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : null}
      <header>
        <Header />
        <Navbar />
      </header>
      {/* <div className="d-flex flex-direction-column" style={{margin: '0 5%'}}>
        <div style={style.astuce}>Astuce</div>
        <div  style={style.main}>
        <Outlet key={'mainOutlet'} />
        </div>
        <div  style={style.pub}>
          <img src={Logo}  style={style.logoLeft} alt='logo'/>
        </div>
      </div> */}

      <div className="row" style={{ margin: '0 2%' }}>
        <div className="col-sm-2 order-3 order-sm-1" style={style.astuce}>
          <LeftSide />
        </div>
        <div className="col-sm-8  order-1 order-sm-2" style={style.main}>

          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><i className="fas fa-home px-3"></i><a href="/">Acceuil</a></li>
              {breadcrumb}
            </ol>
          </nav>

          <Outlet key={'mainOutlet'} />
        </div>
        <div className="col-sm-2 order-2 order-sm-3" style={style.pub}>
          <RightSide />
        </div>
      </div>
      <div>
        <a target='blank' rel='noreferrer' href={socials.telegram} className="stiky-bottom" id="bottom-link">
          <i data-toggle="tooltip" title="Rejoint nous sur  telegram" data-placement="left" class="fa-brands fa-important  fixed-link fa-telegram" style={{ color: '#229ED9', borderRadius: '100%' }}></i>
        </a>
        <Footer />
      </div>

    </div>
  )
}


const style = {
  astuce: {
    //backgroundColor: '#eee',
    padding: '0.5% 0.5%',
    minHeight: '200px',
    //boxShadow: '1px 0px 2px rgba(34, 34, 34, 0.529)',
    //flex: 1,
  },
  main: {
    padding: '0.5% 1%',
    //flex: 3,
  },
  pub: {
    //backgroundColor: '#eee',
    padding: '0.5% 0.5%',
    minHeight: '200px',
    //flex: 1,
  },
  logoLeft: {
    width: '100px',
    height: '100px',
  }
}

export default Layout;